import _objectSpread from "@babel/runtime-corejs3/helpers/objectSpread2";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import storeModule, { storeModuleName } from '../product_app/store/modules/advertising';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import AdvertisingRecommend from './components/AdvertisingRecommend/Index.vue';
import Banner from './components/Banner';
import HomeIcon from './components/HomeIcon';
import BigCard from './components/BigCard';
import { DefaultSearchBox } from '@shein-aidc/bs-search-box-mobile-vue2';
import '@shein-aidc/bs-search-box-mobile-vue2/style.css'

import SSticky from "@shein/sui-mobile/lib/sticky";
import hiddenBackTopMixins from 'public/src/pages/advertising/mixins/hiddenBackTopMixins';
import advertisingAnalysis from './analysis';
import analysis from 'public/src/pages/common/search_input/analysis/index';
import { checkGoodsStock } from './common/utils';
import { cloneDeep } from "@shein/common-function/dist/esm/event/cloneDeep";
import { getQueryString } from "@shein/common-function/dist/esm/url/getQueryString";
import { stringifyQueryString } from "@shein/common-function/dist/esm/url/stringifyQueryString";
import { onClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOn';
import { offClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOff';
var exposeSearchTextArr = [];
export default {
  name: 'AdvertisingPage',
  components: {
    Banner,
    AdvertisingRecommend,
    HomeIcon,
    BigCard,
    DefaultSearchBox,
    SSticky
  },
  mixins: [hiddenBackTopMixins],
  provide() {
    return {
      advertisingAnalysis,
      getIsSupportCropImage: function getIsSupportCropImage() {
        return true;
      }
    };
  },
  // SSR 调用 将数据注入仓库
  asyncData(_ref) {
    var store = _ref.store,
      context = _ref.context;
    if (!store.state[storeModuleName]) {
      var _context$cat_info;
      store.registerModule(storeModuleName, storeModule);
      if ((context === null || context === void 0 || (_context$cat_info = context.cat_info) === null || _context$cat_info === void 0 ? void 0 : _context$cat_info.pageName) === 'page_advertising') {
        // 首屏数据不做变更 但是不能直接冻结context 因为vue在ssr时还会对context对象做一些拓展
        var contextValue = Object.freeze(cloneDeep(context));
        store.state[storeModuleName].contextForSSR = Object.freeze(contextValue);
      }
    }
  },
  data() {
    return {
      isFirst: true,
      // 首次进入
      defaultWords: [] // 底纹词数组
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(storeModuleName, ['pageType', 'hooksGoods', 'locals', 'language', 'sheinClubInfo', 'catInfo', 'fetchSearchTermsParams'])), mapGetters(storeModuleName, ['bannerImg'])), {}, {
    isShowBigCard() {
      var _this$hooksGoods, _this$hooksGoods2;
      // A分支 有勾子商品 且在售
      return this.pageType === 'A' && (this === null || this === void 0 || (_this$hooksGoods = this.hooksGoods) === null || _this$hooksGoods === void 0 ? void 0 : _this$hooksGoods.goods_id) && checkGoodsStock(this === null || this === void 0 ? void 0 : this.hooksGoods) && (this === null || this === void 0 || (_this$hooksGoods2 = this.hooksGoods) === null || _this$hooksGoods2 === void 0 ? void 0 : _this$hooksGoods2.goods_name);
    },
    // 参见需求:pageId=1500482628
    isShowSearchInput() {
      return this.pageType === 'B' && getQueryString({
        key: 'search'
      }) === 'show';
    }
  }),
  created() {
    var _ref2 = this.$store.state[storeModuleName] || {},
      contextForSSR = _ref2.contextForSSR;
    if (contextForSSR) {
      this.resetLocals(contextForSSR);
      this.initData(contextForSSR);
      advertisingAnalysis.init({
        hooksGoods: this === null || this === void 0 ? void 0 : this.hooksGoods
      });
    }
  },
  activated() {
    if (!this.isFirst) {
      var _window$sessionStorag;
      advertisingAnalysis.init({
        hooksGoods: this === null || this === void 0 ? void 0 : this.hooksGoods
      });
      this.resetAnalysis();
      var userClickProductLatest = JSON.parse(((_window$sessionStorag = window.sessionStorage) === null || _window$sessionStorag === void 0 ? void 0 : _window$sessionStorag.getItem('userClickProductLatest')) || '{}');
      var _ref3 = userClickProductLatest || {},
        detailBackCatId = _ref3.cat_id,
        detailBackGoodsId = _ref3.goods_id;
      detailBackGoodsId && this.changeFetchSearchTermsParams({
        key: 'goods_id',
        value: detailBackGoodsId
      });
      detailBackCatId && this.changeFetchSearchTermsParams({
        key: 'cat_id',
        value: detailBackCatId
      });
      window.sessionStorage.removeItem('userClickProductLatest'); // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
    }
    this.isFirst = false;
  },
  mounted() {
    onClickProductCardInGoodsDetail(function (args) {
      var _window$sessionStorag2;
      (_window$sessionStorag2 = window.sessionStorage) === null || _window$sessionStorag2 === void 0 || _window$sessionStorag2.setItem('userClickProductLatest', _JSON$stringify(args));
    });
    if (this.isFirst) {
      advertisingAnalysis.init({
        hooksGoods: this === null || this === void 0 ? void 0 : this.hooksGoods
      });
      this.resetAnalysis();
    }
  },
  beforeDestroy() {
    offClickProductCardInGoodsDetail();
  },
  methods: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapMutations(['assignRootState'])), mapActions(storeModuleName, ['initState'])), mapMutations(storeModuleName, ['setHooksGoods', 'setAbtInfo', 'setPageType', 'initItemConfig', 'setLanguage', 'resetLocals', 'changeFetchSearchTermsParams'])), {}, {
    resolveSearchTerms(keywords, result) {
      var _keywords$, _keywords$2, _keywords$3;
      // 曝光第一条数据
      this.defaultWords = keywords;
      if (this.defaultWords.length === 0) {
        analysis.exposeSearch({
          defaultWords: '',
          placeholder: '',
          localIndex: 0
        });
        return;
      }
      window.sessionStorage.setItem('advertisingDefaultWords', _JSON$stringify(keywords !== null && keywords !== void 0 ? keywords : []));
      // 为了处理：UG-148607
      var ref = this.$refs.defaultSearchBoxRef;
      if (ref && ref.updateSwiper) {
        ref.updateSwiper();
      }
      if (_includesInstanceProperty(exposeSearchTextArr).call(exposeSearchTextArr, (_keywords$ = keywords[0]) === null || _keywords$ === void 0 ? void 0 : _keywords$.word) || !((_keywords$2 = keywords[0]) !== null && _keywords$2 !== void 0 && _keywords$2.word)) {
        return;
      }
      analysis.exposeSearch({
        defaultWords: [keywords[0]],
        placeholder: keywords[0],
        localIndex: 0
      });
      exposeSearchTextArr.push((_keywords$3 = keywords[0]) === null || _keywords$3 === void 0 ? void 0 : _keywords$3.word);
    },
    // 轮播回调曝光
    searchTermTransitionEnd(item, index) {
      if (_includesInstanceProperty(exposeSearchTextArr).call(exposeSearchTextArr, item === null || item === void 0 ? void 0 : item.word)) {
        return;
      }
      analysis.exposeSearch({
        defaultWords: [item],
        placeholder: this.defaultWords[0],
        localIndex: index
      });
      exposeSearchTextArr.push(item === null || item === void 0 ? void 0 : item.word);
      return;
    },
    // 点击跳转搜索结果页
    handleSearchButtonClick(item, index) {
      var _item$type, _payload$item, _window;
      // 如果底纹词为空，跳转预搜页
      if (!item) {
        this.handleSearchTermClick(null); // 传null, 是保证为空时和直接点击搜索框跳预搜页的传值一样
        return;
      }
      var payload = {
        item,
        index
      };
      analysis.search({
        keyword: (item === null || item === void 0 ? void 0 : item.word) || '-',
        full_name: '-',
        result_type: 3,
        search_redir: '-',
        data: {
          position: (payload === null || payload === void 0 ? void 0 : payload.index) + 1,
          type: (_item$type = item === null || item === void 0 ? void 0 : item.type) !== null && _item$type !== void 0 ? _item$type : '-'
        },
        crowdId: (item === null || item === void 0 ? void 0 : item.crowdId) || '-',
        originWord: (item === null || item === void 0 ? void 0 : item.word) || '-',
        pageType: 'all',
        trend_list_content: '-'
      });
      var currentSearchTermWord = payload === null || payload === void 0 || (_payload$item = payload.item) === null || _payload$item === void 0 ? void 0 : _payload$item.word;
      var currentSearchTermIndex = payload === null || payload === void 0 ? void 0 : payload.index;
      var params = {
        src_identifier: `st=${3}\`sc=${currentSearchTermWord}\`sr=0\`ps=${currentSearchTermIndex + 1}`,
        src_module: 'search',
        src_tab_page_id: ((_window = window) === null || _window === void 0 || (_window = _window.getSaPageInfo) === null || _window === void 0 ? void 0 : _window.tab_page_id) || ''
      };
      var url = `${this.langPath}/pdsearch/${encodeURIComponent(currentSearchTermWord)}/?${stringifyQueryString({
        queryObj: params
      })}`;
      this.$router.push(url);
    },
    // 点击跳转预搜页（从header的input扣出来的跳转预搜逻辑）
    handleSearchTermClick(searchTerm) {
      var _window2;
      var payload = {
        placeholder: searchTerm
      };
      analysis.clickSearch(payload);
      // 将当前轮换的默认词，显示在搜索页输入框内
      if (payload !== null && payload !== void 0 && payload.placeholder) {
        var _this$defaultWords;
        gbCommonInfo.keywords = '';
        this.assignRootState({
          currentDefaultKeyword: payload.placeholder.word,
          searchSelectKeyWord: '',
          mixedKeyword: '',
          inputDefaultWordsIndex: (_this$defaultWords = this.defaultWords) === null || _this$defaultWords === void 0 ? void 0 : _this$defaultWords.findIndex(function (i) {
            var _payload$placeholder;
            return (i === null || i === void 0 ? void 0 : i.word) === ((_payload$placeholder = payload.placeholder) === null || _payload$placeholder === void 0 ? void 0 : _payload$placeholder.word);
          })
        });
      } else {
        gbCommonInfo.keywords = '';
        this.assignRootState({
          mixedKeyword: '',
          searchSelectKeyWord: '',
          currentDefaultKeyword: ''
        });
      }

      // 标识进入新页面
      this.$route.meta.isNewPage = true;
      this.$route.meta.cameraFrom = null;
      var pagefrom = (_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.SaPageInfo) === null || _window2 === void 0 ? void 0 : _window2.page_name;
      var query = {
        pageType: 'all',
        pagefrom
      };
      if (this.isGoodsDetailPage) {
        var _this$goodsDetailInfo, _this$goodsDetailInfo2;
        Object.assign(query, {
          goods_id: (_this$goodsDetailInfo = this.goodsDetailInfo) === null || _this$goodsDetailInfo === void 0 ? void 0 : _this$goodsDetailInfo.goodsId,
          cate_id: (_this$goodsDetailInfo2 = this.goodsDetailInfo) === null || _this$goodsDetailInfo2 === void 0 ? void 0 : _this$goodsDetailInfo2.catId
        });
      }
      var src_identifier = getQueryString({
        key: 'src_identifier'
      });
      if (src_identifier) {
        query.src_identifier = src_identifier;
      }
      this.$router.push({
        path: `${this.langPath}/presearch`,
        query
      });
    },
    initData(payload) {
      payload && this.initState(payload);
    },
    resetAnalysis() {
      var _this$$refs$refBigCar, _this$$refs$refBigCar2, _this$$refs$refRecomm, _this$$refs$refRecomm2;
      advertisingAnalysis.sendPV();
      (_this$$refs$refBigCar = this.$refs.refBigCard) === null || _this$$refs$refBigCar === void 0 || (_this$$refs$refBigCar2 = _this$$refs$refBigCar.resetAnalysis) === null || _this$$refs$refBigCar2 === void 0 || _this$$refs$refBigCar2.call(_this$$refs$refBigCar);
      (_this$$refs$refRecomm = this.$refs.refRecommend) === null || _this$$refs$refRecomm === void 0 || (_this$$refs$refRecomm2 = _this$$refs$refRecomm.resetAnalysis) === null || _this$$refs$refRecomm2 === void 0 || _this$$refs$refRecomm2.call(_this$$refs$refRecomm);
    }
  })
};