function toHump (name) {
  return name.replace(/-(\w)/g, function(all, letter){
    return letter.toUpperCase()
  })
}

function getComponentProps (props) {
  if (Object.prototype.toString.call(props) === '[object Array]') {
    return props.reduce((res, curr) => {
      res[curr] = { type: null }
      return res
    }, {})
  }
  return props
}

export default function lazyComponent ({
  componentFactory,
  options,
  root = null,
  rootMargin = '20% 0px 20% 0px',
  threshold = [0],
  prefetch = false,
  emitLoaded = false,
  propWhole = false
}) {
  if (process.env.VUE_ENV == 'client') {
    if (typeof window === 'undefined' || !('IntersectionObserver' in window)) {
      return componentFactory
    }
  }
  let isAsync = Object.prototype.toString.call(componentFactory) === '[object Function]'
  const forceProp = { 
    lazyForceShow: {
      type: Boolean,
      default: false
    },
    lazyWrapOptions: {
      type: Object,
      default () {
        return {}
      }
    }
  }
  const result = {
    inheritAttrs: false,
    props: {
      ...forceProp,
      ...(isAsync ? {} : getComponentProps(componentFactory.props))
    },
    data () {
      return {
        isLazy: true,
        emitLoaded,
        loaded: false,
        propsKey: null,
      }
    },
    computed: {
      placeholderOptions () {
        return Object.keys(this.lazyWrapOptions).length ? this.lazyWrapOptions : null
      }
    },
    watch: {
      lazyForceShow: {
        immediate: true,
        handler (val) {
          if (val) {
            this.renderMe()
          }
        }
      }
    },
    created () {
      if (prefetch) {
        this.getComponent()
      }
    },
    mounted () {
      if (!this.lazyForceShow) {
        appEventCenter && appEventCenter.$once('allLazyComponentRender', this.renderMe)
        const observer = new IntersectionObserver(entries => {
          if (entries.every(entry => entry.intersectionRatio <= 0)) return
          
          // 停止观察
          observer.unobserve(this.$el)
          // 加载组件
          this.renderMe()
        }, {
          root,
          rootMargin,
          threshold,
        })
        
        // 开始观察
        observer.observe(this.$el)
        this.$once('hook:beforeDestroy', () => {
          observer.disconnect()
        })
      }
    },
    beforeDestroy () {
      appEventCenter && appEventCenter.$off('allLazyComponentRender', this.renderMe)
    },
    methods: {
      renderMe () {
        this.getComponent()
        this.isLazy = false
      },
      onLoaded () {
        this.$nextTick(() => {
          this.emitLoaded = false
        })
      },
      onMounted () {
        this.$nextTick(() => {
          this.loaded = true
        })
      },
      getComponent () {
        if (isAsync) {
          componentFactory().then(res => {
            if (res.default.name === 'RecommendBottom') {
              appEventCenter.$emit('recommendBottomLoaded')
            } 
            this.propsKey = Object.keys(res?.default?.props || {})
          })
        }
      },
    },
    render (h) {
      const opt = this.placeholderOptions || options || {}
      
      const renderBool = !this.lazyForceShow && this.isLazy
      if ((isAsync && (!this.propsKey || renderBool)) || renderBool) {
        return h('div', opt)
      }

      const slots = Object.keys(this.$slots)
        .reduce((arr, key) => arr.concat(this.$slots[key]), [])
        .map(vnode => {
          vnode.context = this._self
          return vnode
        })

      let props = {}, attrs = {}
      if (!isAsync) {
        props = this.$props
        attrs = this.$attrs
      } else {
        for (let attrKey in this.$attrs) {
          if (this.propsKey?.includes?.(toHump(attrKey))) {
            props[attrKey] = this.$attrs[attrKey]
          } else {
            attrs[attrKey] = this.$attrs[attrKey]
          }
        }
      }

      const ele = h(componentFactory, {
        on: {
          ...this.$listeners,
          lazyLoaded: () => this.onLoaded(),
          'hook:mounted': () => this.onMounted()
        },
        props,
        attrs,
        scopedSlots: this.$scopedSlots
      }, slots)
      // if (this.loaded && !this.emitLoaded) {
      //   return ele
      // }
      return h('div', this.loaded && !this.emitLoaded ? {} : opt, [ele])
    }
  }
  if (isAsync && propWhole) {
    return () => (new Promise(resolve => {
      componentFactory().then(res => {
        result.props = Object.assign({}, result.props, getComponentProps(res.default.props))
        // 加载完之后设置为同步组件（伪）
        isAsync = false
        resolve(result)
      }).catch(() => {
        resolve(componentFactory())
      })
    }))
  } else {
    return result
  }
}
